<template>
  <div>
    <base-spinner v-if="loading" large />
    <div v-else class="mb-32">
      <div class="modules mb-16">
        <div class="modules-navigation">
          <base-multiselect
            :value="activeModules"
            @toggle-option="toggleActiveModule"
            @reset="resetModules"
            :options="moduleOptions"
            theme="gray"
            :loading="modulesFetching.length > 0"
            reset
            class="monitoring-modules-select mobile"
          />

          <ul class="monitoring-modules mb-4 mb-tablet-0 tablet-desktop">
            <li v-for="module in modules" :key="module.id">
              <button
                class="monitoring-modules__button mb-2"
                :class="{ active: activeModules.includes(module.id) }"
                @click="toggleActiveModule(module.id)"
              >
                {{ module.name }}
              </button>
            </li>
            <li v-if="modulesFetching.length > 0">
              <base-spinner small />
            </li>
          </ul>
          <div class="modules-actions">
            <router-link to="/app/account#modules" class="add-module">
              <div>
                <svg
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.75306 0.763672V8.12949"
                    stroke="#0042FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.43555 4.44713H1.06973"
                    stroke="#0042FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <span class="tablet-desktop">
                dodaj moduł
              </span>
            </router-link>
          </div>
        </div>
        <div class="modules-beloved">
          <div>
            <span>pokaż tylko ulubione</span>
          </div>
          <base-switch v-model="belovedOnly" name="belovedOnly" simple small>
          </base-switch>
        </div>
      </div>

      <div class="item-list">
        <slot v-if="visibleItems.length > 0" :items="visibleItems" />
        <base-spinner v-else-if="modulesFetching.length > 0" />
        <div v-else class="no-modules">
          Brak wyników
        </div>
        <base-more-button v-if="hasMore" @click="showNext" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapItems } from '../util/mapItems';
import { mapGetters, mapMutations } from 'vuex';

const VISIBLE_COUNT = 10;

export default {
  props: {
    api: Object,
    mapItems: Function,
  },
  data() {
    return {
      items: [],
      belovedOnly: false,
      loading: false,
      page: 1,
      itemsByModule: {},
      favoriteHated: {
        favorite: [],
        hated: [],
      },
      modulesFetching: [],
      modules: null,
      activeModules: [],
    };
  },
  computed: {
    ...mapGetters('list', {
      pksByModule: 'pksByModule',
    }),
    hasMore() {
      return this.items.length !== this.visibleItems.length;
    },
    visibleItems() {
      return this.items.slice(0, this.page * VISIBLE_COUNT);
    },
    // items() {
    //   return mapItems({
    //     itemsByModule: this.itemsByModule,
    //     activeModules: this.activeModules,
    //     favoriteHated: this.favoriteHated,
    //   });
    // },
    moduleOptions() {
      return this.modules.map(module => ({
        name: module.name,
        value: module.id,
      }));
    },
  },
  watch: {
    activeModules: function(active) {
      this.setActiveModules(
        this.modules.filter(module => active.includes(module.id))
      );
    },
    modules: function(modules) {
      this.setModules(modules);
    },
    belovedOnly: function() {
      this.items = mapItems({
        itemsByModule: this.itemsByModule,
        activeModules: this.activeModules,
        favoriteHated: this.favoriteHated,
        belovedOnly: this.belovedOnly,
      });
    },
  },
  created() {
    this.setApi(this.api);
    this.fetchData();
  },
  destroyed() {
    this.clearListState();
  },
  methods: {
    ...mapMutations('list', [
      'setActiveModules',
      'setPksByModule',
      'setFavoriteHated',
      'setModules',
      'clearListState',
      'setApi',
    ]),
    async resetModules() {
      let activeModules = JSON.parse(JSON.stringify(this.activeModules));
      for (let i = 0; i < activeModules.length; i++) {
        await this.toggleActiveModule(activeModules[i]);
      }
      this.activeModules = [];
    },
    showNext() {
      this.page++;
    },
    async toggleActiveModule(id) {
      console.log(id);
      this.page = 1;
      if (this.activeModules.includes(id)) {
        this.activeModules = this.activeModules.filter(m => m !== id);
      } else {
        this.activeModules = [...this.activeModules, id];
        if (this.itemsByModule[id] === undefined) {
          await this.fetchModuleItems(id);
        }
      }
      window.localStorage.setItem(
        'active-monitoring-modules',
        this.activeModules
          .map(id => {
            const module = this.modules.find(module => module.id === id);
            return module.name;
          })
          .join(',')
      );
      this.items = mapItems({
        itemsByModule: this.itemsByModule,
        activeModules: this.activeModules,
        favoriteHated: this.favoriteHated,
        belovedOnly: this.belovedOnly,
      });
    },
    async fetchUserModules() {
      if (this.api.modulesWithName) {
        const modules = this.$store.state.user[this.api.modulesWithName];
        this.modules = modules.map(module => ({
          id: module[this.api.moduleId],
          name: module.name,
        }));
      } else {
        const modulesData = await this.$api.get('v1/modules/');
        const userModules = this.$store.state.user.custom_multi_query;

        this.modules = modulesData.data.results
          .filter(
            module =>
              userModules.includes(module.pk) && module[this.api.moduleId]
          )
          .map(module => ({
            id: module[this.api.moduleId],
            name: module.name_to_display,
          }));
      }
      if (this.modules.length === 0) {
        return;
      }
      const previousActiveModuleNames = window.localStorage.getItem(
        'active-monitoring-modules'
      );
      if (!previousActiveModuleNames) {
        this.toggleActiveModule(this.modules[0].id);
        return;
      }
      const activeModuleNames = previousActiveModuleNames.split(',');
      const activeModules = this.modules.filter(module =>
        activeModuleNames.includes(module.name)
      );
      if (activeModules.length === 0) {
        this.toggleActiveModule(this.modules[0].id);
        return;
      }
      activeModules.forEach(module => this.toggleActiveModule(module.id));
    },
    async fetchModuleItems(moduleId) {
      this.modulesFetching.push(moduleId);
      const { data } = await this.$api.get(
        `v1/${this.api.modules}/${moduleId}/`
      );
      this.itemsByModule = {
        ...this.itemsByModule,
        [moduleId]: this.mapItems(data.generate_cached_only_queryset),
      };
      this.setPksByModule({
        ...this.pksByModule,
        [moduleId]: data.get_cached_only_pk_list,
      });
      this.modulesFetching = this.modulesFetching.filter(m => m !== moduleId);
      this.items = mapItems({
        itemsByModule: this.itemsByModule,
        activeModules: this.activeModules,
        favoriteHated: this.favoriteHated,
      });
    },
    async fetchFavoriteHated() {
      const { data } = await this.$api.get(`v1/${this.api.favoriteHated}/`, {
        cache: {
          ignoreCache: true,
        },
      });
      const favoriteHatedRaw = data.results[0][this.api.favoriteHatedResponse];
      this.setFavoriteHated(favoriteHatedRaw);
      this.favoriteHated = {
        favorite: favoriteHatedRaw
          ? this.mapItems(favoriteHatedRaw.favorite)
          : [],
        hated: favoriteHatedRaw ? favoriteHatedRaw.hateful : [],
        excludedFromModule: favoriteHatedRaw
          ? favoriteHatedRaw.excluded_from_module
          : [],
        beloved:
          favoriteHatedRaw && favoriteHatedRaw.beloved
            ? favoriteHatedRaw.beloved
            : [],
      };
    },
    async fetchData() {
      this.loading = true;
      this.$store.dispatch(this.api.bookmarks);
      await this.fetchFavoriteHated();
      await this.fetchUserModules();
      for (let i in this.modules) {
        this.fetchModuleItems(this.modules[i].id);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'MonitoringItems';
</style>
