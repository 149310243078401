<template>
  <div :class="{ narrow }" v-escape="closeItems">
    <div class="table-header tablet-desktop mb-4">
      <div class="title">tytuł zarządzenia lub projektu</div>
      <div class="stage">status projektu</div>
    </div>
    <div v-for="(item, index) in items" :key="item.id" :id="item.slug">
      <healthfund-item
        @toggleOpen="event => toggleItem(index, event)"
        @loaded="fixScrollPosition(item.id)"
        :searchHighlights="searchHighlights"
        :open="item.open"
        :listItem="item"
        :narrow="narrow"
        ref="item"
      />
    </div>
  </div>
</template>

<script>
import HealthfundItem from '../HealthfundItem/HealthfundItem';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    HealthfundItem,
  },
  props: {
    searchHighlights: {
      query: String,
      keywords: Array,
    },
    items: Array,
    narrow: Boolean,
    ownApi: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      api: {
        moduleId: 'pk',
        modulesWithName: 'custom_query_nfz_with_names',
        modules: 'module_nfz_header',
        favoriteHated: 'favorite_and_hated_nfz_header',
        favoriteHatedResponse: 'favorite_and_hateful_ordinances_list',
        bookmarks: 'bookmarks/fetchHealthfund',
        list: 'healthfund',
        listModules: 'custom_multi_query_nfz',
      },
    };
  },
  computed: {
    ...mapGetters({
      isModalActive: 'isModalActive',
    }),
  },
  watch: {
    narrow(value) {
      if (value) {
        this.items.forEach(item => {
          item.open = false;
        });
      }
    },
  },
  created() {
    if (this.ownApi) {
      this.setApi(this.api);
      this.getFavoriteHated();
      this.setModulesForCurrentList();
    }
  },
  destroyed() {
    if (this.ownApi) {
      this.clearListState();
    }
  },
  methods: {
    ...mapMutations({
      openProject: 'openProject',
      closeProject: 'closeProject',
    }),
    ...mapMutations('list', ['setApi', 'clearListState']),
    ...mapActions('list', ['getFavoriteHated', 'setModulesForCurrentList']),
    closeItems() {
      if (this.isModalActive) {
        return;
      }
      this.items.forEach(item => {
        item.open = false;
      });
    },
    toggleItem(index, event = null) {
      if (event && (event.ctrlKey || event.button === 1)) {
        if (event.type === 'mousedown') {
          event.preventDefault();
        } else {
          const route = this.$router.resolve({
            name: 'healthfund-item',
            params: { slug: this.items[index].slug },
          });
          window.open(window.location.origin + route.href, '_blank').focus();
        }
      } else if (event.type === 'mouseup') {
        this.items.forEach((item, itemIndex) => {
          if (itemIndex === index) {
            item.open = !item.open;
            this.$emit('toggle-item', item.open);
          } else {
            item.open = false;
          }
        });
      }
    },
    fixScrollPosition(index) {
      setTimeout(() => {
        const scroll = this.$store.getters['user/isLoggedIn'] ? 110 : 145;
        const element = this.$refs.item.find(
          item => item.listItem.id === index
        );
        const fromTop = element.$el.getBoundingClientRect().top;
        window.scrollTo(0, window.scrollY - (scroll - fromTop));
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  padding: 1rem;
  padding-right: 7.5rem;
  border-bottom: 2px solid $gray-5;
  color: $gray-2;
  position: relative;
  z-index: 10;
}

.title {
  width: 80%;
}
.stage {
  width: 20%;
  text-align: center;
}
.narrow {
  .title {
    width: 75%;
  }
  .stage {
    width: 25%;
  }
}
</style>
