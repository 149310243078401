import { createPrepareItem } from '../../../util/item/createPrepareItem';

export const healthfundItemPrepare = createPrepareItem('ordinance_summary', [
  {
    name: 'Zarządzenia nowelizowane',
    listKey: 'amended_list',
    highlightKey: 'amended_nfz_with_keywords_in_title',
    highlightSearchKey: 'amended_nfz_with_search_keywords_in_title',
    valueKey: 'amended_provisions',
    highlightTooltipName: {
      plural: 'zarządzeń objętych',
      singular: 'Zarządzenie jest objęte',
    },
  },
  {
    name: 'Zarządzenia uchylane',
    listKey: 'derogated_list',
    highlightKey: 'derogated_nfz_with_keywords_in_title',
    highlightSearchKey: 'derogated_nfz_with_search_keywords_in_title',
    valueKey: 'derogated_provisions',
    highlightTooltipName: {
      plural: 'zarządzeń objętych',
      singular: 'Zarządzenie jest objęte',
    },
  },
  {
    name: 'Wdrażane akty europejskie',
    listKey: 'eu_related_list',
    highlightKey: 'eu_related_with_keywords_in_title',
    highlightSearchKey: 'eu_related_with_search_keywords_in_title',
    valueKey: 'eu_related_provisions',
    highlightTooltipName: {
      plural: 'aktów objętych',
      singular: 'Akt jest objęty',
    },
  },
]);
