<template>
  <monitoring-items
    :activeModules="activeModules"
    :mapItems="mapHealthItems"
    :api="api"
    #default="{items}"
  >
    <healthfund-list
      :items="items"
      :ownApi="false"
    />
  </monitoring-items>
</template>

<script>
import MonitoringItems from '../components/MonitoringItems';
import HealthfundList from '../../Healthfund/HealthfundList';
import { mapHealthItems } from '../../Healthfund/mapHealthItems';

export default {
  props: {
    activeModules: Array,
  },
  metaInfo() {
    return {
      title: 'Monitoring NFZ',
    };
  },
  components: {
    MonitoringItems,
    HealthfundList,
  },
  data() {
    return {
      api: {
        moduleId: 'pk',
        modulesWithName: 'custom_query_nfz_with_names',
        modules: 'module_nfz_header',
        favoriteHated: 'favorite_and_hated_nfz_header',
        favoriteHatedResponse: 'favorite_and_hateful_ordinances_list',
        bookmarks: 'bookmarks/fetchHealthfund',
        list: 'healthfund',
      },
      mapHealthItems,
    };
  },
};
</script>
