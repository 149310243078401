<template>
  <item
    :config="config"
    :open="open"
    :searchHighlights="searchHighlights"
    :listItem="listItem"
    :narrow="narrow"
    v-on="$listeners"
    class="healthfund-item"
  >
    <div v-if="listItem" class="item__closed-stage">{{ listItem.stage }}</div>
  </item>
</template>

<script>
import Item from '../../components/Item/Item';
import { healthfundItemPrepare } from './util/healthfundItemPrepare';
import { mapActions, mapMutations } from 'vuex';

export default {
  components: { Item },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `${window.location.origin}${this.$route.fullPath}`,
        },
      ],
    };
  },
  props: {
    open: Boolean,
    searchHighlights: {
      query: String,
      keywords: Array,
    },
    listItem: Object,
    narrow: Boolean,
  },
  data() {
    return {
      apiModules: {
        moduleId: 'pk',
        modulesWithName: 'custom_query_nfz_with_names',
        modules: 'module_nfz_header',
        favoriteHated: 'favorite_and_hated_nfz_header',
        favoriteHatedResponse: 'favorite_and_hateful_ordinances_list',
        bookmarks: 'bookmarks/fetchHealthfund',
        list: 'healthfund',
        listModules: 'custom_multi_query_nfz',
      },
      config: {
        routeName: 'healthfund-item',
        routeList: 'healthfund',
        route404: 'healthfund',
        prepareData: healthfundItemPrepare,
        api: {
          body: 'nfz_body',
          highlights: 'healthfund/load_highlights',
          monitored: 'healthfund/load_provisions_with_favorite_flag',
          additional: 'nfz_provisions_and_versions',
          textVersions: {
            lastVersion: 'v1/nfz_last_version_button',
            version: 'v1/nfz_version',
          },
          descriptions: 'v1/nfz_other_descriptions',
        },
        bookmark: {
          type: 'healthfund',
          fetch: 'fetchHealthfund',
          toggle: 'toggleHealthfund',
        },
        labels: {
          linksTitle: 'Projekt na stronie:',
          linksDoneTitle: 'Zarządzenie na stronie:',
          attachmentsDraftTitle: 'Załączniki do projektu:',
          attachmentsIssuedTitle: 'Załączniki do zarządzenia:',
        },
      },
    };
  },
  created() {
    if (!this.listItem) {
      this.setApi(this.apiModules);
      this.getFavoriteHated();
      this.setModulesForCurrentList();
    }
  },
  destroyed() {
    if (!this.listItem) {
      this.clearListState();
    }
  },
  methods: {
    ...mapMutations('list', ['setApi', 'clearListState']),
    ...mapActions('list', ['getFavoriteHated', 'setModulesForCurrentList']),
  },
};
</script>

<style lang="scss">
.healthfund-item {
  @include mq('tablet') {
    .item--narrow {
      .item__closed-title {
        width: 75%;
      }
      .item__closed-stage {
        width: 25%;
      }
    }

    .item__closed-title {
      width: 80%;
      padding-right: 1.5rem;
    }
    .item__closed-stage {
      width: 20%;
      text-align: center;
    }
  }
}
</style>
